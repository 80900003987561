import React from "react"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ReactDOM from 'react-dom/client';

import Home from "./pages/home";
import Layout from "./layout";
import "./index.css"
import Blog from "./pages/blog";
import Projects from "./pages/projects";
import Downloads from "./pages/downloads";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="blog" element={<Blog/>} />
        <Route path="projects" element={<Projects/>} />
        <Route path="downloads" element={<Downloads/>} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);