import { Outlet } from "react-router-dom";

import Drawer from "./components/drawer";

export default function Layout() {
    return   <div className="flex flex-row">
            <Drawer />
            <div className="p-5" style={{marginLeft: 104}}>
                <Outlet />
            </div>
        </div> 
}