
import "@material/web/list/list"
import "@material/web/list/list-item"
import { NavLink } from "react-router-dom";
import logo from "../SVG/logo-personal.svg"
import { useEffect, useRef } from "react";
import anime from 'animejs/lib/anime.es.js'; 
import "./drawer.css"
const LinkButton = ({
    path,
    label
}) => {
    const animationRef =  useRef(null);
    useEffect(() => {
       animationRef.current = anime({
         targets: ".link",
         translateY: 5,
         delay: function(el, i) {
           return i * 100;
         },
         loop: true,
         direction: "alternate",
         easing: "easeInOutSine"
       });
     }, []);
    return (
    <NavLink to={path} type="button">
      
            <div  className="link">{label}</div>
        
    </NavLink>
)
}
function Drawer() {
    const animationRef =  useRef(null);
 useEffect(() => {
    animationRef.current = anime({
      targets: "#logo",
      translateY: 5,
      delay: function(el, i) {
        return i * 100;
      },
      loop: true,
      direction: "alternate",
      easing: "easeInOutSine"
    });
  }, []);

    return <div
    className="fixed flex flex-col items-center"
        style={{
            width: 104,
             minWidth: 104,
            height: "100vh",
            background: "var(--md-sys-color-surface, #fef7ff)"
        }}
       >
        <img id="logo"  alt="Vipin Dev S Logo" src={logo} width={40} className="p-2"/>
        <md-list style={{width: "100%"}}>
            <LinkButton path="/" label="Home" type="button" />
            <LinkButton path="/blog" label="Blog" type="button" />
            <LinkButton path="/projects" label="Projects" type="button" />
            <LinkButton path="/downloads" label="Downloads" type="button" />
        </md-list>
    </div>
}

export default Drawer;